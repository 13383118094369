.home-header {
  height: 8rem;
  position: static;
  width: 63rem;
  align-items: center;
  justify-content: center;

  &__img {
    width: 90%;
    height: auto;
  }
}

.footer {
  &__logo {
    @media screen and (min-width: 1000px) {
      width: 13rem;
      height: 2rem;
    }
    @media screen and (max-width: 1000px) {
      width: 14rem;
    }
    @media screen and (max-width: 768px) {
      width: 10rem;
      height: 1.6rem;
    }
  }
}
