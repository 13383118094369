.d-flex {
  display: flex;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.flex-column {
  flex-direction: column;
}


.bg-transparent {
  background-color: 'transparent';
}

.text-center {
  text-align: center;
}

.center {
  margin: 0 auto;
}

.h-100 {
  height: 100%;
}
