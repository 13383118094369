@import './variables';
@import './utils';
@import './layouts';

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  width: 100vw;
  padding-bottom: 10vw;
}

.modal {
  &__img {
    width: 17rem;
    height: 17rem;
    @media screen and (max-width: 1200px) {
      width: 14rem;
      height: 14rem;
    }
    @media screen and (max-width: 900px) {
      width: 15rem;
      height: 15rem;
    }
    @media screen and (max-width: 600px) {
      width: 10rem;
      height: 8rem;
    }
    @media screen and (max-width: 500px) {
      width: 7rem;
      height: 7rem;
    }
  }
}
